import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '../constants/localstorage.constant';
import { CryptoService } from './crypto.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  constructor(
    public localStorageService: LocalStorageService,
    private cryptoService: CryptoService
  ) { }

  setToken(token: string): void {
    const encryptedString = this.cryptoService.encryptValue(token);
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CALENSO_PUBLIC_ROUTES_AUTH_TOKEN, encryptedString);
  }

  getToken(): string | null {
    const encryptedEvent = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CALENSO_PUBLIC_ROUTES_AUTH_TOKEN) as string;
    if (encryptedEvent) {
      return this.cryptoService.decryptValue(encryptedEvent) as string;
    } else {
      return null;
    }
  }

  deleteToken(): void {
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.CALENSO_PUBLIC_ROUTES_AUTH_TOKEN);
  }
}
