import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {LoggerService} from './logger.service';
import {HelperService} from './helper.service';
import {DialogService} from './dialog.service';
import {APP_CONSTANTS} from '@constants/app.constants';
import {PartnerService} from '../feature-services/partner.service';
import {PartnerDbModel} from '../db-models/partner-db.model';
import {AppointmentServiceCategoryDbModel} from '../db-models/appointment-service-category-db.model';
import {CustomFieldModel} from '../db-models/custom-field.model';
import {WorkerService} from '../feature-services/worker.service';
import {WorkerDbModel} from '../db-models/worker-db.model';
import {HttpErrorResponse} from '@angular/common/http';
import {StoreDbModel} from '../db-models/store-db.model';
import {AppointmentServicesFormComponent} from '../../calio-dynamic-lib/appointment-forms/appointment-services-form/appointment-services-form.component';
import {StoreService} from '../feature-services/store.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertToastrService} from './alert-toastr.service';
import {TaxDbModel} from '../db-models/tax.model';
import {CategoryDbModel} from '../db-models/category-db.model';
import {CustomerTypeDbModel} from '../db-models/customer-type-db.model';

@Injectable({
  providedIn: 'root'
})
export class CalioRouterService {

  currentOpenDialog: any;

  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService,
    private dialogService: DialogService,
    private partnerService: PartnerService,
    private workerService: WorkerService,
    private storeService: StoreService,
    private translateService: TranslateService,
    private alertToastrService: AlertToastrService,
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      if (params?.goto) {
        setTimeout(() => {
          this.helperService.scrollToElementById(params?.goto, true);
        }, 1000);
      }
      if (params?.action) {
        setTimeout(() => {
          this.handleActionEvent(params?.action);
        }, 1000);
      }
    });
  }

  handleActionEvent(action: string): void {
    if (this.currentOpenDialog) {
      return;
    }

    switch (action) {
      case 'add-service-category':
        this.addServiceCategory();
        break;
      case 'add-booking-question':
        this.addNewQuestion();
        break;
      case 'add-resource':
        this.addNewEmployee();
        break;
      case 'add-calendar':
        this.openAddCalendarDialog();
        break;
      case 'add-store':
        this.openAddStore();
        break;
      case 'add-tax':
        this.openAddTax();
        break;
      case 'add-meeting-provider':
        this.openAddMeetingProvider();
        break;
      case 'add-availability':
        this.openAddAvailability();
        break;
      case 'add-absence':
        this.openAddAbsence();
        break;
      case 'add-calendar-type':
        this.openAddCalendarType();
        break;
      case 'add-customer-type':
        this.openCustomerType();
        break;
    }
  }

  addServiceCategory() {
    this.currentOpenDialog = this.dialogService.openServiceCategoryDialog({
      appointmentServiceCategoryDbModel: new AppointmentServiceCategoryDbModel(),
      formType: APP_CONSTANTS.APPOINTMENT_SERVICE_CATEGORY_NEW_FORM_TYPE,
      showCancelButton: true,
    }, 'create_service_category_component.add_service_category').afterClosed().subscribe({
      next: (result: AppointmentServiceCategoryDbModel) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  addNewQuestion() {
    const customFieldDbModel = new CustomFieldModel();
    this.currentOpenDialog = this.dialogService.openQuestionFieldFormDialog({
        customFieldDbModel,
        formType: APP_CONSTANTS.QUESTION_FIELD_NEW_FORM_TYPE
      }, 'event_question_fields_component.new_question'
    ).afterClosed().subscribe({
      next: (result: CustomFieldModel) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  addNewEmployee() {
    this.workerService.getLimitedWorkersList().subscribe({
      next: (workers: WorkerDbModel[]) => {
        this.currentOpenDialog = this.dialogService.openEmployeeFormDialog({
          formType: APP_CONSTANTS.EMPLOYEE_NEW_FORM_TYPE,
          workerDbModels: workers?.length > 0 ? workers : []
        }).afterClosed().subscribe({
          next: (result: any) => {
            if (result) {
              window.location.href = window.location.pathname;
            }
            this.currentOpenDialog = undefined;
          }
        });
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  openAddCalendarDialog(body?: {
    code?: string,
    calendarType?: string,
    workerId?: number,
    showWorkerOptionsInForm?: boolean,
    redirectTo?: boolean
  }) {
    if (!body) {
      body = {};
    }
    body.showWorkerOptionsInForm = true;
    body.redirectTo = false;

    this.currentOpenDialog = this.dialogService.openCalendarSetupDialog
    (
      body,
      'external_calendar_settings_component.calendar_dialog_title'
    ).afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  openAddStore(): void {
    this.storeService.all().subscribe({
      next: (stores: StoreDbModel[]) => {
        if (stores?.length > 0) {
          stores = this.helperService.sortByNumber(stores, 'position');
        } else {
          stores = [];
        }
        const partner: PartnerDbModel = this.partnerService.getPartnerFromLocalStorage();
        if (partner && stores?.length >= partner.licensed_stores) {
          this.alertToastrService.showError(this.translateService.instant('error_messages.stores_limit_error_message', {
            number: partner.licensed_stores
          }));
          return;
        }

        const model = new StoreDbModel();
        this.currentOpenDialog = this.dialogService.openStoresFormDialog({
          storeDbModel: model,
          formType: APP_CONSTANTS.STORE_NEW_FORM_TYPE
        }, 'stores_component.add_title').afterClosed().subscribe({
          next: (result: AppointmentServicesFormComponent) => {
            if (result) {
              window.location.href = window.location.pathname;
            }
            this.currentOpenDialog = undefined;
          }
        });
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log('Error is ', error);
      }
    });
  }

  openAddTax(): void {
    const taxDbModel = new TaxDbModel();
    this.currentOpenDialog = this.dialogService.openTaxesFormDialog({
      taxDbModel,
      formType: APP_CONSTANTS.TAX_NEW_FORM_TYPE
    }, 'taxes_settings_component.add').afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  openAddMeetingProvider(body?: {
    code?: string,
    meetingType?: string,
    workerId?: number,
    showWorkerOptionsInForm?: boolean,
    redirectTo?: number
  }) {
    if (!body) {
      body = {};
    }
    body.showWorkerOptionsInForm = true;
    body.redirectTo = 0;
    this.currentOpenDialog = this.dialogService.openMeetingSetupDialog
    (
      body,
      'meetings_settings_component.meeting_dialog_title'
    )
      .afterClosed()
      .subscribe({
        next: (result: any) => {
          if (result) {
            window.location.href = window.location.pathname;
          }
          this.currentOpenDialog = undefined;
        }
      });
  }

  openAddAvailability() {
    this.currentOpenDialog = this.dialogService.openRecurringAvailabilitiesFormDialog(
      {
        worker: new WorkerDbModel(),
        screenType: APP_CONSTANTS.AVAILABILITIES_FORM,
        formType: APP_CONSTANTS.ADD,
        showWorkerOptionsInForm: true
      }, 'recurring_availability_component.new_availability'
    ).afterClosed().subscribe({
      next: (result: WorkerDbModel) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  openAddAbsence() {
    const partnerDbModel: PartnerDbModel = this.partnerService.getPartnerFromLocalStorage();
    this.currentOpenDialog = this.dialogService.openAbsensesFormDialog({
      worker: new WorkerDbModel(),
      partnerDbModel,
      showWorkerOptionsInForm: true
    }, 'absence_component.new_absence').afterClosed().subscribe({
      next: (result: WorkerDbModel) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  openAddCalendarType(): void {

    const categoryDbModel = new CategoryDbModel();

    this.currentOpenDialog = this.dialogService.openCalendarTypesFormDialog(
      {
        formType: APP_CONSTANTS.CATEGORY_NEW_FORM_TYPE,
        categoryDbModel,
      },
      'calendar_types_list_component.add_title'
    ).afterClosed().subscribe({
      next: (result: AppointmentServicesFormComponent) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }

  openCustomerType(): void {
    const model = new CustomerTypeDbModel();
    this.currentOpenDialog = this.dialogService.openCustomerTypesFormDialog({
      customerTypeDbModel: model,
      formType: APP_CONSTANTS.CUSTOMER_TYPE_NEW_FORM_TYPE
    }, 'customer_types_form_component.add_title').afterClosed().subscribe({
      next: (result: AppointmentServicesFormComponent) => {
        if (result) {
          window.location.href = window.location.pathname;
        }
        this.currentOpenDialog = undefined;
      }
    });
  }
}
