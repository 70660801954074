import { HttpErrorResponse, HttpInterceptorFn, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from '@constants/app.constants';
import { IS_CALENSO_AUTH_REQUIRED, LANDING_PAGE_AUTH_REQUIRED } from '@constants/http-context-token';
import { environment } from '@environments/environment';
import { AuthService } from '@feature-services/auth.service';
import { LandingPagesAuthTokenHandlerService } from '@feature-services/landing-pages-auth-token-handler.service';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { AuthTokenService } from '@util-services/auth-token.service';
import { HelperService } from '@util-services/helper.service';
import { catchError, filter, map } from 'rxjs/operators';

export const NoopHttpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {

  const whiteListUrls: string[] = [];
  // URLs which needs to be execlude JWT for some specific case only
  const whiteListMixedUrls = [
    '/appointments/view_by_uuid',
    '/customers_slots/view_by_uuid',
    '/customers/',
  ];

  APP_CONSTANTS.LANGUAGES.forEach(language => whiteListUrls.push(`./assets/i18n/${language.locale}.json`));

  const auth = inject(AuthService);
  const alertToastrService = inject(AlertToastrService);
  const router = inject(Router);
  const helperService = inject(HelperService);
  const authTokenService = inject(AuthTokenService);
  const landingPagesAuthTokenHandlerService = inject(LandingPagesAuthTokenHandlerService);

  let errorToastInInterceptor = true;

  if (
    (req.method === 'POST' || req.method === 'DELETE') &&
    req?.body?.error_toast_in_interceptor !== undefined &&
    req?.body?.error_toast_in_interceptor === false
  ) {
    errorToastInInterceptor = false;
    delete req.body.error_toast_in_interceptor;
  }

  if (
    req.method === 'GET' &&
    req.params.get("error_toast_in_interceptor") !== undefined &&
    req.params.get("error_toast_in_interceptor") === 'false'
  ) {
    errorToastInInterceptor = false;
  }

  let snapshotData: { [key: string]: any } = {};
  if (router.routerState['_root'].children?.length) {
    snapshotData = router.routerState['_root'].children[0]?.value.snapshot.data;
  }

  // if snapshot has exclueJWT key in data we need to skip
  // JWT for the requests which are listed in whiteListMixedUrls
  if (snapshotData?.exclueJWT === true) {
    let skipToken = false;

    skipToken = !whiteListMixedUrls.every(url => {
      return !(req.url.indexOf(url) > -1);
    });

    if (skipToken) {
      return next(req);
    }
  }

  if (whiteListUrls.indexOf(req.url) > -1) {
    return next(req);
  }

  const headers: {
    [name: string]: string | string[];
  } = {};

  let tokenReq = req;
  let authorizationToken: string;

  // Get token from session storage to support open landing page
  if (req.context.has(LANDING_PAGE_AUTH_REQUIRED)) {
    const landingPageAuthUuid = req.context.get(LANDING_PAGE_AUTH_REQUIRED);
    authorizationToken = landingPagesAuthTokenHandlerService.get(landingPageAuthUuid);
  }

  // Fallback use auth token from local storage
  if (!authorizationToken) {
    authorizationToken = auth.getAuthTokenFromLocalStorage();
  }

  if (authorizationToken) {
    headers['Authorization'] = `Bearer ${authorizationToken}`;
    headers['X-Calenso-Auth'] = `true`;
    tokenReq = req.clone({
      setHeaders: headers
    });
  }

  return next(tokenReq).pipe(
    filter((event): event is HttpResponse<any> => event instanceof HttpResponse),
    map(event => {
      const body = event.body;

      // Check in case url is from node then handling behaviour would be differnet
      if (checkUrlIsFromServices(event.url)) {
        return event.clone({
          body: event?.body?.data
        });
      }

      if (event?.url?.includes('postcodes/import')) {
        return event;
      }

      if (body && body.errors && body.errors[0]) {
        throw new HttpErrorResponse({
          error: new Error(body.errors[0].message),
          statusText: body.errors[0].message,
          status: body.errors[0].code
        });
      } else if (body && body.errors) {
        if (body.errors.username && body.errors.username.unique) {
          throw new HttpErrorResponse({
            error: new Error(body.errors.username.unique),
            statusText: body.errors.username.unique,
            status: HttpStatusCode.BadRequest
          });
        } else if (body.errors.email && body.errors.email.unique) {
          throw new HttpErrorResponse({
            error: new Error('Email is already registered.'),
            statusText: 'Email is already registered.',
            status: HttpStatusCode.BadRequest
          });
        } else if (body.errors.code === 'malware') {
          throw new HttpErrorResponse({
            error: new Error(body.errors.message),
            statusText: body.errors.code,
            status: HttpStatusCode.BadRequest
          });
        } else if (body.errors.code === 'file-size') {
          throw new HttpErrorResponse({
            error: new Error(body.errors.message),
            statusText: body.errors.code,
            status: HttpStatusCode.BadRequest
          });
        } else if (body.errors) {
          throw new HttpErrorResponse({
            error: body.errors,
            statusText: 'Something went wrong.',
            status: HttpStatusCode.BadRequest,
          });
        }
      } else if (body && body.error) {
        if (body.message) {
          throw new HttpErrorResponse({
            error: new Error(body.message),
            statusText: body.message,
            status: body.status
          });
        }
      } else {
        // Check request has IS_CALENSO_AUTH_REQUIRED context if so look for Calenso Auth
        // token inside response and store it in localstorage
        if (req.context.has(IS_CALENSO_AUTH_REQUIRED)) {
          const token = helperService.findKeyValueFromObject(event.body, 'token');
          token && authTokenService.setToken(token);
        }

        return event;
      }
    }),
    catchError((error: HttpErrorResponse) => {
      // Check in case url is from node then handling behaviour would be differnet
      if (error?.url && checkUrlIsFromServices(error.url)) {
        throw error;
      }

      if (error.status === HttpStatusCode.Unauthorized) {
        auth.logout({ "token-expired": true });
      }

      if (error.status === HttpStatusCode.Forbidden) {
        if (errorToastInInterceptor) {
          alertToastrService.showError(`general.403_error_message`);
        }
      }
      throw error;
    })
  );
}

const checkUrlIsFromServices = (url: string): boolean => {
  return (
    url.includes(environment.authServiceHost) ||
    url.includes(environment.exportNodeServiceHost) ||
    url.includes(environment.notificationNodeServiceHost)
  );
}
