export class CategoryDbModel {
  id: number;
  uuid: string;
  name: string;
  html_color: string;
  partner_id: number;
  is_multi_language: boolean;
  _translations: {
    [key: string]: CategoryDbTranslationModel
  };
}

export class CategoryDbTranslationModel {
  locale: string;
  name: string;
}
