import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '../util-services/http-client.service';
import { LoggerService } from '../util-services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  #configs = new BehaviorSubject<Params | null>(null);
  readonly configs$ = this.#configs.asObservable().pipe(
    filter((configs) => !!configs)
  );

  constructor(private httpClientService: HttpClientService) {}

  getConfigs() {
    this.httpClientService.getJsonFile(`/${environment.configJsonPath}?cache=${new Date().getTime()}`, {}).subscribe({
      next: (configs: Params) => {
        // Update environment secret variables
        configs?.stripeConnectUrl && (environment.stripeConnectUrl = configs.stripeConnectUrl);
        configs?.stripeCoupon && (environment.stripeCoupon = configs.stripeCoupon);
        configs?.bexioConf && (environment.bexioConf = configs.bexioConf);
        configs?.googleConf && (environment.googleConf = configs.googleConf);
        configs?.gotoMeetingConfig && (environment.gotoMeetingConfig = configs.gotoMeetingConfig);
        configs?.office365Conf && (environment.office365Conf = configs.office365Conf);
        configs?.webexConfig && (environment.webexConfig = configs.webexConfig);
        configs?.zoomConfig && (environment.zoomConfig = configs.zoomConfig);

        LoggerService.log('[APP_INITIALIZER] Configuration are aligned with server!');
        this.#configs.next(configs);
      },
      error: (error: HttpErrorResponse) => {
        this.#configs.next(null);

        LoggerService.warn('[APP_INITIALIZER] Configuration service call failed to get data from server!');
        LoggerService.error(error);
      }
    });
  }
}
