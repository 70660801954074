import { EventEmitter, Injectable } from '@angular/core';
import { BreadCrumb } from "@client-models/breadcrumb.model";
import { CustomFieldValueRequestBody } from '@db-models/calendar-appointment';
import { CustomFieldModel } from '@db-models/custom-field.model';
import { SmartWidgetModel, SmartWidgetStepModel } from '@db-models/smart-widget.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalioEventsService {

  addEventSlotsEvent = new EventEmitter<any>();
  toggleMobileMenu = new EventEmitter<boolean>();
  closeMobileMenu = new EventEmitter<boolean>();
  hideHeaderEvent = new EventEmitter<boolean>();
  hideGlobalSearchEvent = new EventEmitter<boolean>();
  gotoSettingsRightSectionEvent = new EventEmitter<any>();
  closeCalioDialogEvent = new EventEmitter<any>();
  closeCalioRefDialogEvent = new EventEmitter<any>();
  onToggleButton = new EventEmitter<any>();
  calioDialogTitleChangedEvent = new EventEmitter<{
    title?: string,
    subtitle?: string
  }>();
  calioDashboardHeaderDataChanged = new EventEmitter<{
    title: string,
    subtitle: string,
    breadcrumbs: BreadCrumb[],
    permissionTypes?: string | string[],
    showLastItemCustomLabel?: boolean,
    lastItemCustomLabel?: string,
    isTitleNotTranslatable?: boolean,
  }>();
  resetIsMultiDayToggleButton = new EventEmitter<any>();
  resetWebsiteIntegrationWidgetConfigEvent = new EventEmitter<any>();
  resetBookingPageConfigEvent = new EventEmitter<any>();
  updateSmartWidgetEvent = new EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }>();
  nextStepperStepEvent = new EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }>();
  previousStepperStepEvent = new EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }>();
  bookingQuestionReorderingEvent = new EventEmitter<{
    selectedCustomFields: CustomFieldModel[],
    pageType: string,
    stepDbModel?: SmartWidgetStepModel,
  }>();
  bookingQuestionUpdatedOnLandingPageEvent = new BehaviorSubject<boolean>(false);
  bookingQuestionsPayloadOnLandingPageEvent = new Subject<{ [key: string]: CustomFieldValueRequestBody }>();
  bookingQuestionCancelEditOnLandingPageEvent = new Subject<boolean>();
}
