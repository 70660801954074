import { AppointmentServiceDbModel } from './appointment-service.model';
import { BookingTypesDbModel } from './booking-types.model';
import { ConferenceStatusType } from './conference-db.model';
import { PartnerDbModel } from './partner-db.model';
import { StoreDbModel } from './store-db.model';

export class WorkerDbModel {
  id: number;
  prename: string;
  lastname: string;
  booking_label: string;
  avatar: string;
  gender: string;
  partner_id: number;
  username: string;
  email: string;
  job_title: string;
  conference_url: string;
  bookable: number;
  store_id: number;
  store_name: string;
  photo_dir: string;
  registration_date: Date;
  updated: Date;
  first_login: number;
  street: string;
  zip: string;
  city: string;
  mobile: string;
  timezone: string;
  hide_past_appointments: number;
  show_in_pro_calendar: number;
  is_reseller_admin: number;
  is_round_robin_enabled: number;
  status: number;
  uuid: string;
  store: StoreDbModel;
  full_name: string;
  services_workers: ServiceWorkerDbModel[];
  resource_name: string;
  isSelected?: boolean;
  is_resource: number;
  description: string;
  booking_type_id: number;
  booking_type: BookingTypesDbModel;
  password: string;
  password_repeat: string;
  color: string;
  partner: PartnerDbModel;
  is_multi_language: number;
  is_lead_generator: number;
  roles: AuthRole[];
  lang_identifier: string;
  calendar_color: string;
  block_resource_dependencies: string;
  is_mfa_enabled: number;
  base_account_partner_id: number;
  show_availabilities_in_calendar: number;
  appointment_booking_limit: number;
  has_pending_export: number;
  worker_id?: number;
  _translations: {[key: string]: WorkerDbModelTranslations}[];
  conference_filter_state?: ConferenceStatusType;
}

export class WorkerDbModelTranslations {
  job_title?: string;
  booking_label?: string;
  description?: string;
  locale?: string;
  full_name?: string;
  resource_name?: string;
}

export class ServiceWorkerDbModel {
  id: number;
  appointment_service_id: number;
  worker_id: number;
  appointment_service: AppointmentServiceDbModel;
}

export class AuthRole {
  id: number;
  name: string;
  description: string;
  partner_id: number;
  price: null;
  subscription_relevant: number;
  active: number;
  calenso_free: number;
  calenso_digital_solution: number;
  calenso_premium: number;
  read_only: number;
  calenso_lite: number;
  calenso_corporate: number;
}
