<div class="d-flex align-items-center justify-content-center vh-100 mx-5">
  @if (showPaymentVerificationLoader) {
    <div class="row">
      <div class="col-md-12">
        <div class="subtitle">
          <br>
          {{ 'saferPayment.verifyingPayment' | translate }}
        </div>
      </div>
    </div>
  } @else {
    @if (paymentSuccess) {
      <div class="row">
        <div class="col-md-12">
          <div class="subtitle">
            <img
              alt="Success Icon"
              src="/assets/images/check_round.svg"
              height="150"
              width="150"
              class="mb-4"
            />
            <br>
            {{ 'saferPayment.successTitle' | translate }}
          </div>
          <div class="mt-5 main-text">
            {{ 'saferPayment.securityNote' | translate }}
          </div>
        </div>
      </div>
    } @else {
      <div class="row">
        <div class="col-md-12">
          <div class="subtitle">
            <img
              alt="Warning Icon"
              src="/assets/images/warning_ico.svg"
              height="150"
              width="150"
              class="mb-4"
            />
            <br>
            {{ paymentFailedMessage || ('saferPayment.failedTitle' | translate) }}
          </div>
            <div class="mt-5 main-text">
              {{ 'saferPayment.failedNote2' | translate }}
              <br />
              <br />
              {{ 'saferPayment.failedNote1' | translate }}
            </div>
        </div>
      </div>
    }
  }
</div>
