import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  readonly #hostName = environment.hostName;
  readonly #restAPI = environment.restAPI;
  readonly #v2RestAPI = environment.v2RestAPI;
  readonly #authServiceHost = environment.authServiceHost;
  readonly #authServiceRestApiV1 = environment.authServiceRestApiV1;
  readonly #exportNodeServiceHost = environment.exportNodeServiceHost;
  readonly #exportNodeServiceRestApiV1 = environment.exportNodeServiceRestApiV1;
  readonly #notificationNodeServiceHost = environment.notificationNodeServiceHost;
  readonly #notificationNodeServiceRestApiV1 = environment.notificationNodeServiceRestApiV1;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  // region: cake service methods start
  getJsonFile(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.#hostName + path, options);
  }

  get(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.#hostName + this.#restAPI + path, options);
  }

  post(path: any, body: any, options): Observable<any> {
    return this.httpClient.post(this.#hostName + this.#restAPI + path, body, options);
  }

  put(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.put(this.#hostName + this.#restAPI + path, body, options);
  }

  delete(path: any, options: any): Observable<any> {
    return this.httpClient.delete(this.#hostName + this.#restAPI + path, options);
  }

  v2Post(path: any, body: any, options): Observable<any> {
    return this.httpClient.post(this.#hostName + this.#v2RestAPI + path, body, options);
  }
  // region: cake service methods end

  // region: auth service methods start
  authServicePost(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.post(this.#authServiceHost + this.#authServiceRestApiV1 + path, body, options);
  }

  authServiceGet(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.#authServiceHost + this.#authServiceRestApiV1 + path, options);
  }

  authServicePut(path: any, body: any, options): Observable<any> {
    return this.httpClient.put(this.#authServiceHost + this.#authServiceRestApiV1 + path, body, options);
  }

  authServiceDelete(path: any, options: any): Observable<any> {
    return this.httpClient.delete(this.#authServiceHost + this.#authServiceRestApiV1 + path, options);
  }
  // region: auth service methods end

  // region: export service methods start
  exportServicePost(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.post(this.#exportNodeServiceHost + this.#exportNodeServiceRestApiV1 + path, body, options);
  }

  exportServiceGet(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.#exportNodeServiceHost + this.#exportNodeServiceRestApiV1 + path, options);
  }
  // region: export service methods end

  // region: notification service methods start
  notificationServicePost(path: any, body: any, options: any): Observable<any> {
    return this.httpClient.post(this.#notificationNodeServiceHost + this.#notificationNodeServiceRestApiV1 + path, body, options);
  }

  notificationServiceGet(path: any, options: any): Observable<any> {
    return this.httpClient.get(this.#notificationNodeServiceHost + this.#notificationNodeServiceRestApiV1 + path, options);
  }
  // region: notification service methods end
}
