export const INTERVAL = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
} as const;

export const SUPPORTED_LANGUAGES = {
  DE_CH: 'de_CH',
  EN_US: 'en_US'
} as const;

export const CURRENCY = {
  CHF: 'CHF',
  EUR: 'EUR'
} as const;

export const RENDER_MODE = {
  DASHBOARD: 'dashboard',
  WEBSITE: 'website',
  SALES: 'sales',
} as const;

export const SUBSCRIPTION = {
  BASIC: 'basic',
  PROFESSIONAL: 'professional',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise'
} as const;

export const SUBSCRIPTION_ID = {
  basic: 103,
  professional: 102,
  business: 104,
  enterprise: 105
} as const;

export const SUBSCRIPTION_CONSTANTS = {
  SUBSCRIPTION_FREE_TYPE: {
    id: 1,
    name: 'free',
    desc: 'Calenso Free',
  },
  SUBSCRIPTION_FLATRATE_TYPE: {
    id: 5,
    name: 'flatrate',
    desc: 'Calenso Flatrate',
  },
  SUBSCRIPTION_WHITELABEL_TYPE: {
    id: 100,
    name: 'whitelable',
    desc: 'Calenso Whitelable',
  },
  SUBSCRIPTION_ENTERPRISE_TYPE: {
    id: 101,
    name: 'enterprise',
    desc: 'Calenso Enterprise',
  },
  SUBSCRIPTION_PROFESSIONAL_TYPE: {
    id: 102,
    name: 'professional',
    desc: 'Calenso professional',
  },
  SUBSCRIPTION_BASIC_TYPE: {
    id: 103,
    name: 'basic',
    desc: 'Calenso Basic',
  },
  SUBSCRIPTION_BUSINESS_TYPE: {
    id: 104,
    name: 'business',
    desc: 'Calenso Business',
  },
  SUBSCRIPTION_NEW_ENTERPRISE_TYPE: {
    id: 105,
    name: 'newEnterprise',
    desc: 'Calenso New Enterprise',
  }
}

export const VALID_SUBSCRIPTION_IDS = [
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_FREE_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_FLATRATE_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_WHITELABEL_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_ENTERPRISE_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_BASIC_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_PROFESSIONAL_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_BUSINESS_TYPE.id,
  SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_NEW_ENTERPRISE_TYPE.id,
];

export const EXTRA_USER_COST = 5;
export const EXTRA_GROUP_APPOINTMENT_COST = 10;
export const EXTRA_STORE_COST = 10;
export const EXTRA_CONFERENCE_COST = 10;

export const VAT_TAX_PERCENTAGE = 8.1;

export const CUSTOM_PLAN_USER_TYPE = 'additional_users';
export const CUSTOM_PLAN_STORE_TYPE = 'additional_stores';
export const CUSTOM_PLAN_GROUP_APPOINTMENT_TYPE = 'additional_events';
export const CUSTOM_PLAN_CONFERENCE_TYPE = 'additional_conferences';

export const ENTERPRISE_REDIRECTION_LINK = 'https://calenso.com/demo';

export const SALES_TOKEN = '82e80dc8-9318-4be5-9be1-e8ef79859fca';

export const PAYMENT_INTENT_RESPONSE_STATUS = {
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
}

export const STRIPE_SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
  INCOMPLETE: 'incomplete',
  TRIALING: 'trialing',
  EXPIRED: 'expired',
  PAUSED: 'paused'
} as const;
