import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { WorkerService } from '@feature-services/worker.service';
import { HelperService } from '@util-services/helper.service';
import { LoggerService } from '@util-services/logger.service';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export const WorkersResolveFn: ResolveFn<WorkerDbModel[]> = (
  route: ActivatedRouteSnapshot
) => {
  const workerService = inject(WorkerService);
  const helperService = inject(HelperService);
  return workerService.getLimitedWorkersList().pipe(
    switchMap((workers: WorkerDbModel[]) => {
      if (workers?.length > 0) {
        return of(helperService.sortByNumber(workers, 'prename'));
      } else {
        return of([]);
      }
    }),
    catchError((error: HttpErrorResponse) => {
      LoggerService.error(error);
      if (error.status === 403) {
        return of([]);
      }
      throw error;
    })
  );
};
