import { inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { PartnerService } from '@feature-services/partner.service';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { HelperService } from '@util-services/helper.service';

export const OnboardGuard = (_route: Route, segments: UrlSegment[]): boolean => {

  const partnerService = inject(PartnerService);
  const router = inject(Router);
  const alertToastrService = inject(AlertToastrService);
  const helperService = inject(HelperService);
  const partner = partnerService.getPartnerFromLocalStorage();
  const urlPath = helperService.getUrlPathFromSegments(segments);

  if (partner?.uuid) {
    if (partner?.is_setup_done === 1) {
      if (urlPath?.includes('onboard')) {
        router.navigate(['/']);
        return false;
      }
      return true;
    } else if (partner?.is_setup_done === 0) {
      if (urlPath?.includes('onboard')) {
        return true;
      } else {
        router.navigate(['/onboard'], {
          queryParams: {
            step: partner.current_setup_step + 1
          }
        }).then(() => {
          if (partner.current_setup_step > 1) {
            alertToastrService.showSuccess('onboard.resume_process_message');
          }
        });
        return false;
      }
    } else if (partner?.is_setup_done === 0) {
      if (urlPath?.includes('onboard')) {
        return true;
      } else {
        router.navigate(['onboard'], {
          queryParams: {
            step: partner.current_setup_step + 1
          }
        }).then(() => {
          if (partner.current_setup_step > 1) {
            alertToastrService.showSuccess('onboard.resume_process_message');
          }
        });
        return false;
      }
    }
    return true;
  }
  return true;
}
