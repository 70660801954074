import { AppointmentServiceDbModel, TranslationDbModel } from './appointment-service.model';

export class AppointmentServiceCategoryDbModel {
  id: number;
  uuid: string;
  name: string;
  partner_id: number;
  is_multi_language: number;
  position: number;
  _translations: {
    [key: string]: TranslationDbModel
  };
  appointment_services: AppointmentServiceDbModel[];
}
