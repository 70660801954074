import { formatDate } from "@angular/common";
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from "@angular/material/core";
import { DATE_FORMAT_DMY } from '@constants/date.constants';

@Injectable({
  providedIn: 'root'
})
export class CalioDateAdapterService extends NativeDateAdapter {

  readonly dateFormatDmy = DATE_FORMAT_DMY.DATE_PIPE;

  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, displayFormat: Object): string {
    return formatDate(date, this.dateFormatDmy, this.locale);
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return super.getDayOfWeekNames('short');
  }
}
