<div class="d-flex justify-content-center heading-box">
  <h3> {{ 'login_component.forbidden_title' | translate }} </h3>
</div>

<div class="d-flex justify-content-center mt-5">
  <img class="forbidden-image" src="/assets/images/forbidden_page.svg" alt="Forbidden Image">
</div>

<div class="d-flex justify-content-center mt-5">
    <p class="w-50 text-center">
        {{ 'login_component.forbidden_desc' | translate }}
    </p>
</div>

<div class="d-flex justify-content-center mt-5">
  <button routerLink="/" class="btn btn-primary next-button" type="submit">
      {{ 'login_component.go_back' | translate }}
  </button>
</div>