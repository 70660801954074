import { inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { SUBSCRIPTION_CONSTANTS } from '@constants/subscription.constants';
import { PartnerService } from '@feature-services/partner.service';
import { WorkerService } from '@feature-services/worker.service';
import { HelperService } from '@util-services/helper.service';

export const MfaGuard = (_route: Route, segments: UrlSegment[]): boolean => {

  const workerService = inject(WorkerService);
  const router = inject(Router);
  const helperService = inject(HelperService);
  const partnerService = inject(PartnerService);
  const worker = workerService.getLoggedInWorkerFromLocalStorage()
  const mfaDetails = workerService.getMfaVerificationDetailsInLocalStorage();
  const partner = partnerService.getPartnerFromLocalStorage();
  const isEnterpricePartner = partner?.subscription?.subscription_type_id >= SUBSCRIPTION_CONSTANTS.SUBSCRIPTION_ENTERPRISE_TYPE.id;
  const urlPath = helperService.getUrlPathFromSegments(segments);

  if (urlPath?.includes('mfa')) {
    if (isEnterpricePartner && worker?.is_mfa_enabled === 1) {
      if (mfaDetails?.verified === true) {
        router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      router.navigate(['/']);
      return false;
    }
  } else {
    if (isEnterpricePartner && worker?.is_mfa_enabled === 1) {
      if (mfaDetails?.verified === true) {
        return true;
      }

      if (urlPath && !urlPath?.includes('mfa')) {
        router.navigate(['/auth', 'mfa'], { queryParams: { 'redirect': urlPath } });
      } else {
        router.navigate(['auth/mfa'])
      }


      return false;
    }
  }
  return true;
}
