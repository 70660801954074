import { Injectable } from '@angular/core';
import { SESSION_STORAGE_CONSTANT } from '../../shared/constants/localstorage.constant';
import { CryptoService } from '../util-services/crypto.service';
import { SessionStorageService } from '../util-services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPagesAuthTokenHandlerService {

  private readonly landingPageAuthTokenConstant = SESSION_STORAGE_CONSTANT.LANDING_PAGE_AUTH_TOKEN

  constructor(
    private sessionStorageService: SessionStorageService,
    private cryptoService: CryptoService
  ) { }

  get(uuid: string): string {
    const value = this.sessionStorageService.get(`${this.landingPageAuthTokenConstant}_${uuid}`);
    if (value) {
      return this.cryptoService.decryptValue(value);
    }
    return null;
  }

  set(uuid: string, token: string): void {
    const value = this.cryptoService.encryptValue(token);
    this.sessionStorageService.set(`${this.landingPageAuthTokenConstant}_${uuid}`, value);
  }

  delete(uuid: string): void {
    this.sessionStorageService.remove(`${this.landingPageAuthTokenConstant}_${uuid}`);
  }
}
