import {Injectable} from '@angular/core';
import {HttpClientService} from '../util-services/http-client.service';
import {Observable} from 'rxjs';
import {PartnerReleaseNotesModel, ReleaseNotesModel} from '../db-models/release-notes.model';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor(
    private httpClientService: HttpClientService,
  ) {
  }

  getReleaseNotes(): Observable<ReleaseNotesModel[]> {
    return this.httpClientService.get('release_notes', {});
  }

  createReleaseNote(body: ReleaseNotesModel): Observable<ReleaseNotesModel> {
    return this.httpClientService.post('release_notes', body, {});
  }

  updateReleaseNote(id: number, body: ReleaseNotesModel): Observable<ReleaseNotesModel> {
    return this.httpClientService.put('release_notes/' + id, body, {});
  }

  deleteReleaseNote(id: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete('release_notes/' + id, {});
  }

  getPartnerReleaseNotes(): Observable<PartnerReleaseNotesModel[]> {
    return this.httpClientService.get('release_notes/partner', {});
  }

  deletePartnerReleaseNote(): Observable<{ success: boolean }> {
    return this.httpClientService.post('release_notes/partner/delete', {}, {});
  }

  generateReleaseNote(body: { id: number }): Observable<{ success: boolean, count: number }> {
    return this.httpClientService.post('release_notes/generate', body, {});
  }
}
