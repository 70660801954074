import { Injectable, inject } from '@angular/core';
import { SaferpayPaymentVerificationDbModel } from '@db-models/saferpay.model';
import { HttpClientService } from '@util-services/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaferpayService {

  private httpClientService = inject(HttpClientService);

  getOrderPaymentStatus(orderUuid: string): Observable<SaferpayPaymentVerificationDbModel> {
    return this.httpClientService.post(`saferpay/verify_transaction_status`, {
      'order-uuid': orderUuid
    }, {});
  }
}
